"use client";

import { createTheme } from "@mantine/core";

export const theme = createTheme({
    colors: {
        violet: ["#efeffc","#cecef7","#bdbdf4","#9b9bee","#7a7ae9","#5959e3","#3939c7","#31319d","#222276","#151550"]
    },
    primaryColor: "violet",
    defaultRadius: "sm",
    headings: {
        sizes: {
            h1: {
                fontSize: "52px",
                fontWeight: 500,
            },
            h2: {
                fontSize: "42px",
                fontWeight: 400,
            },
            h3: {
                fontSize: "32px",
                fontWeight: 400,
            },
            h4: {
                fontSize: "28px",
                fontWeight: 600,
            }
        }
    }
});
