'use client';

import { useDisclosure } from '@mantine/hooks';
import { GridCol, Group, Menu, MenuItem, MenuDropdown, MenuTarget, Stack } from "@mantine/core";
import Link from "next/link";
import { usePathname } from 'next/navigation'
import { Cta } from '@components/Cta/Cta.jsx';
import { Icon } from "@components/Icon/Icon.jsx";
import classes from './Header.module.scss';

export function Navigation({ links, cta }) {
    const [opened, { toggle }] = useDisclosure(false);
    const pathname = usePathname();
    const setOpened = () => {
        setTimeout(() => {
            toggle();
        }, 500);
    }
    return (
        <GridCol span={9}>
            <Group gap={5} direction="left" justify="flex-end" className={`${classes.mainMenu} ${opened ? classes.mainMenuOpen : ''}`}>
                {links.map((item, i) => {
                    if(item.fields.subItems) {
                        return (
                            <Menu key={item.sys.id} trigger="click-hover" transitionProps={{ exitDuration: 0 }} withinPortal={false} className={classes.navItem}>
                                <MenuTarget>
                                    <Link onClick={(event) => event.preventDefault()} href={`${item.fields.url}`} className={`${classes.navLink} ${pathname.includes(item.fields.url) && classes.navLinkCurrent}`}>{item.fields.title}<Icon name="chevron-down" /></Link>
                                </MenuTarget>
                                <MenuDropdown className={classes.subMenu}>
                                    <MenuItem className={classes.navOverview}>
                                        <Link href={item.fields.url} className={classes.navSubLink}>{item.fields.title}</Link>
                                    </MenuItem>
                                    {item.fields.subItems.map((subItem) => {
                                        return (
                                            <MenuItem key={subItem.sys.id} component="div">
                                                <Link href={subItem.fields.url} className={classes.navSubLink}>{subItem.fields.title}</Link>
                                            </MenuItem>
                                        )
                                    })}
                                </MenuDropdown>
                            </Menu>
                        )
                    } else {
                        return (
                            <Link href={item.fields.url} className={`${classes.navLink} ${(pathname.includes(item.fields.url) || (pathname.includes('/news/') && item.fields.url.includes('news-and-events')) || (pathname.includes('/event/') && item.fields.url.includes('news-and-events'))) && classes.navLinkCurrent}`} key={item.sys.id}>{item.fields.title}</Link>
                        )
                    }
                })}

                {cta.fields.url &&
                    <Cta url={`${cta.fields.url}`} title={cta.fields.buttonText} variant={cta.fields.variant} size={cta.fields.size} target={cta.fields.target} />
                }
            </Group>

            <button type="button" className={`${classes.menuToggle} ${opened ? classes.menuToggleOpen : ''}`} aria-label="Toggle menu" onClick={toggle} />
            <Stack direction="vertical" gap="xs" align="center" className={`${classes.mobileMenu} ${opened ? classes.mobileMenuOpen : ''}`}>
                {links.map((item, i) => {
                    if(item.fields.subItems) {
                        return (
                            <div key={item.sys.id} className={classes.mobileSubmenu}>
                                <Link className={classes.navLink} href={item.fields.url} onClick={setOpened}>{item.fields.title}</Link>
                                {item.fields.subItems.map((subItem) => {
                                    return (
                                        <Link key={subItem.sys.id} href={`${subItem.fields.url}`} className={classes.navSubLink} onClick={setOpened}>{subItem.fields.title}</Link>
                                    )
                                })}
                            </div>
                        )
                    } else {
                        return (
                            <Link href={item.fields.url} className={classes.navLink} key={item.sys.id} onClick={setOpened}>{item.fields.title}</Link>
                        )
                    }
                })}

                {cta.fields.url &&
                    <Cta url={`${cta.fields.url}`} title={cta.fields.buttonText} variant={cta.fields.variant} size={cta.fields.size} target={cta.fields.target} onClick={setOpened} />
                }
            </Stack>
        </GridCol>
    )
}